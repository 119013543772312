


























































import coreApiClient from "@/services/apis/coreApiClient";
import downloadFile from "@/utils/downloadFile";
import DataContainer from "@common/modules/vuetifyx/common/DataContainer";
import stateManager from "@common/services/stateManager";
import Vue from "vue";
export default Vue.extend({
  data() {
    const self2 = this;
    return {
      self: this,
      setting: null,
      printerTypes: [
        {
          text: "Raster Link",
          value: "rasterLink",
        },
        {
          text: "Other",
          value: "",
        },
      ],
      printedItems: [],
      printedItemsShow: false,
      printerName: "",
      printerType: "",
      tableOptions: {
        attrs: {
          "item-key": "_id",
        },
        content: {
          name: "Print Design",
          displayFields: {
            productVariant: {
              text: "Name",
              options: {
                subProp: "productVariant.name",
                label: true,
              },
            },
            size: {
              text: "Size",
              options: {
                label: true,
                preTransform(_, item) {
                  return item.productVariant?.propertyValues?.size || item.productVariant?.propertyValues?.Size;
                },
              },
            },
            color: {
              text: "Color",
              options: {
                label: true,
                preTransform(_, item) {
                  return item.productVariant?.propertyValues?.color || item.productVariant?.propertyValues?.Color;
                },
              },
            },
            inProgressQuantity: {
              text: "Printed Quantity",
            },
            quantity: {
              text: "Pending Quantity",
            },
            total: {
              text: "Total",
              options: {
                preTransform(_, item) {
                  return (item.inProgressQuantity || 0) + (item.quantity || 0);
                },
              },
            },
            action: {},
          },
          template: {
            itemActionButtons: {
              print: {
                content: {
                  icon: "mdi-printer",
                },
                on: {
                  async xClick({ self }) {
                    const { item, dataTable } = self.context();
                    self.loading.value = true;
                    await dataTable.execute(
                      async () => {
                        const result = await coreApiClient.callRaw("orderFulfillment", "printDesign", {
                          productVariantId: item.productVariant._id,
                          printerType: self2.printerType,
                        });
                        const setting = self2.setting;
                        if (result) {
                          let printerName = self2.printerName;
                          if (!printerName) {
                            const printerItem = setting.printerItems.find(({ tags }) => {
                              return tags.includes("design");
                            });
                            if (!printerItem) {
                              throw new Error(`No matched printer found for tags: design`);
                            }
                            printerName = printerItem.name;
                          }
                          await coreApiClient.callRaw("print", "print", {
                            printerName,
                            url: result.printUrl,
                            type: "design",
                          });
                          dataTable.refresh();
                          self2.printedItems = result.items;
                          self2.printedItemsShow = true;
                        }
                      },
                      undefined,
                      {
                        disableLoading: true,
                        willRefresh: false,
                      }
                    );

                    self.loading.value = false;
                  },
                },
                target: {
                  tooltip: {
                    content: {
                      text: "Print Design",
                    },
                  },
                },
              },
              advancedPrint: {
                content: {
                  icon: "mdi-cloud-print",
                },
                target: {
                  tooltip: {
                    content: {
                      text: "Advanced Print Design",
                    },
                  },
                  dialog: {
                    handlers: {
                      initialize() {
                        this.options.content.buttons.save.states.enabled = new DataContainer(false);
                      },
                    },
                    attrs: {
                      width: "800px",
                    },
                    content: {
                      toolbar: {
                        title: "Advanced Print Design",
                        subTitle(dialog) {
                          const btn = dialog.context();
                          const { item } = btn.context();
                          return item.productVariant?.name;
                        },
                      },
                      content: {
                        type: "XForm",
                        makeAttrs(attrs, dialog) {
                          attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                          attrs.xContext = dialog;
                          attrs.xData = new DataContainer({});
                          return attrs;
                        },
                        attrs: {
                          xOptions: {
                            content: {
                              sections: {
                                default: {
                                  fields: {
                                    barcodes: {
                                      type: "textMultiple",
                                      attrs: {
                                        label: "Barcodes",
                                      },
                                    },
                                    includeProcessingItems: {
                                      type: "boolean",
                                      attrs: {
                                        label: "Include Processing Items",
                                      },
                                      ext: {
                                        defaultValue: true,
                                      },
                                    },
                                    downloadInsteadOfPrint: {
                                      type: "boolean",
                                      attrs: {
                                        label: "Download Instead of Print",
                                      },
                                      ext: {
                                        defaultValue: true,
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                      buttons: {
                        save: {
                          content: {
                            text: "Submit",
                          },
                          states: {},
                          on: {
                            async xClick({ self }) {
                              const dialog = self.context();
                              const btn = dialog.context();
                              const { dataTable, item } = btn.context();
                              const form = dialog.contentContainer.value;
                              const data = form.getData();
                              self.loading.value = true;
                              await dataTable.execute(
                                async () => {
                                  const result = await coreApiClient.callRaw("orderFulfillment", "printDesign", {
                                    productVariantId: item.productVariant._id,
                                    includeProcessingItems: data.includeProcessingItems,
                                    barcodes: data.barcodes?.join(","),
                                    printerType: self2.printerType,
                                  });
                                  const setting = self2.setting;
                                  if (result) {
                                    if (data.downloadInsteadOfPrint) {
                                      downloadFile(result.printUrl);
                                      return;
                                    }
                                    let printerName = self2.printerName;
                                    if (!printerName) {
                                      const printerItem = setting.printerItems.find(({ tags }) => {
                                        return tags.includes("design");
                                      });
                                      if (!printerItem) {
                                        throw new Error(`No matched printer found for tags: design`);
                                      }
                                      printerName = printerItem.name;
                                    }
                                    await coreApiClient.callRaw("print", "print", {
                                      printerName,
                                      url: result.printUrl,
                                      type: "design",
                                    });
                                    dataTable.refresh();
                                    dialog.hide();
                                    self2.printedItems = result.items;
                                    self2.printedItemsShow = true;
                                  }
                                },
                                undefined,
                                {
                                  disableLoading: true,
                                  willRefresh: false,
                                }
                              );
                              self.loading.value = false;
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const response = await coreApiClient.call("orderFulfillment", "preparePrintDesign");
              let items = response.items || [];
              if (options.search) {
                items = items.filter((item) => {
                  return item.productVariant.name.toLowerCase().includes(options.search.toLowerCase());
                });
              }
              return [items, items.length];
            },
          },
        },
      },
    };
  },
  watch: {
    printerName(value, previousValue) {
      if (value === previousValue) {
        return;
      }
      stateManager.set(`${this.$route.path}_printerName`, value);
    },
    printerType(value, previousValue) {
      if (value === previousValue) {
        return;
      }
      stateManager.set(`${this.$route.path}_printerType`, value);
    },
  },
  async created() {
    this.printerName = stateManager.get(`${this.$route.path}_printerName`, "");
    this.printerType = stateManager.get(`${this.$route.path}_printerType`, "");
    this.setting = await coreApiClient.call("setting", "get");
  },
});
